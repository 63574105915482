import { DepositStatus } from '../types';
import { authStore } from '../store/authStore';
import { DepositInfo, DepositDetailedInfo, DepositCondition, DepositInterval, DepositType } from '../dto';
import { isUndefined } from 'lodash';
import { PER_MONTH_DEPOSIT_TYPE_NAME, END_TERM_DEPOSIT_TYPE_NAME } from '../store/constants';

const DepositStatusCaptions: Record<DepositStatus, string> = {
    [DepositStatus.DRAFT]: 'Оформление',
    [DepositStatus.VERIFIED]: 'Ожидание подписания',
    [DepositStatus.SIGNED]: 'Ожидание оплаты',
    [DepositStatus.PAID]: 'Активный',
    [DepositStatus.CLOSED]: 'Завершен',
};

export const getDepositStatusText = (deposit: DepositInfo) => {
    if (!authStore.isActive) return 'Проверка анкетных данных';

    return DepositStatusCaptions[deposit.status];
};

export const isDepositActive = (deposit: DepositInfo) => deposit.status === DepositStatus.PAID;

export const canAcceptPayment = (deposit: DepositInfo) => deposit.status === DepositStatus.SIGNED || deposit.status === DepositStatus.VERIFIED || deposit.status === DepositStatus.DRAFT;

export const getPaymentPurpose = (deposit: DepositDetailedInfo) =>
    `Инвестиционный займ по Инвестиционному договору № ${deposit.contractNumber} от ${
        deposit.contractDate ? deposit.contractDate.toLocaleDateString() : ''
    }`;

export const getInterval = (conditions: DepositCondition[], sum: number, duration: number) =>
    conditions
        .find(c => c.duration.min <= duration && duration <= c.duration.max)
        ?.intervals.find(i => i.minSum <= sum && (!i.maxSum || (i.maxSum && sum <= i.maxSum)));

export const getMonthlyIncome = (interval: DepositInterval, sum: number) => {
    return (sum * interval.minStatProfitPercent * interval.sharePercent) / 12;
};

export type ConditionForNotice = {
    sum: number;
    durationMin: number;
    durationMax: number;
};

export type DepositSettings = {
    minSum: number | undefined;
    maxSum: number | undefined;
    noticeConditions: ConditionForNotice[];
};

export type DepositReturn = {
    name: string
    id: string
}

export const depositReturns = [{ id: '1', name: 'В конце срока' }, { id: '2', name: 'В любое время'}]

export const getDepositsSettings = (depositTypes: DepositType[]): Map<string, DepositSettings> => {
    return new Map(
        depositTypes.map(dt => [
            dt.id,
            dt.conditions.reduce(
                (
                    acc: {
                        minSum: number | undefined;
                        maxSum: number | undefined;
                        noticeConditions: ConditionForNotice[];
                    },
                    cur
                ) =>
                    cur.intervals.reduce(
                        (
                            accInt: {
                                minSum: number | undefined;
                                maxSum: number | undefined;
                                noticeConditions: ConditionForNotice[];
                            },
                            curInt
                        ) => {
                            const curMaxSum = !curInt.maxSum ? curInt.minSum : curInt.maxSum;

                            return {
                                minSum: isUndefined(accInt.minSum)
                                    ? curInt.minSum
                                    : curInt.minSum < accInt.minSum
                                      ? curInt.minSum
                                      : accInt.minSum,
                                maxSum: isUndefined(accInt.maxSum)
                                    ? curMaxSum
                                    : curMaxSum > accInt.maxSum
                                      ? curMaxSum
                                      : accInt.maxSum,
                                noticeConditions: !curInt.maxSum
                                    ? [
                                          ...accInt.noticeConditions,
                                          {
                                              durationMin: cur.duration.min,
                                              durationMax: cur.duration.max,
                                              sum: curMaxSum,
                                          },
                                      ]
                                    : accInt.noticeConditions,
                            };
                        },
                        {
                            minSum: acc.minSum,
                            maxSum: acc.maxSum,
                            noticeConditions: acc.noticeConditions,
                        }
                    ),
                { minSum: undefined, maxSum: undefined, noticeConditions: [] }
            ),
        ])
    );
};

/** Не инвесткопилка */
export const isOldDepositType = (deposit: DepositDetailedInfo | DepositInfo) => deposit.name !== PER_MONTH_DEPOSIT_TYPE_NAME && deposit.name !== END_TERM_DEPOSIT_TYPE_NAME
