import { Button, styled } from '@mui/material';
import { isUndefined } from 'lodash';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { DELTA_DURATION } from '../../../store/constants';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { Text } from '../../../components/Text';
import { Slider } from '../../../components/Slider';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

export type ButtonSwitchProps = {
    options: Array<{ label: string; value: string }>;
    value: string;
    onChange: (v: string) => void;
    disabled?: boolean;
};

const Root = styled('div')({
    display: 'flex',
    gap: 18,
    flexWrap: 'wrap',
});

const OptionButton = styled(Button)({
    borderRadius: 7,
    height: 50,
    flex: '1 1 calc(25% - 18px)',
});

export const SelectDuration = observer(({ disabled}: { disabled: boolean }) => {
    const [sliderDuration, setSliderDuration] = useState<boolean>(false)

    const durationOptions = useMemo(() => [
        { label: '1 мес.', value: '1' },
        { label: '3 мес.', value: '3' },
        { label: '6 мес.', value: '6' },
        { label: '9 мес.', value: '9' },
        { label: '12 мес.', value: '12' },
        { label: '24 мес.', value: '24' },
        { label: '36 мес.', value: '36' },
        { label: '60 мес.', value: '60' },
        { label: 'Выбрать свой срок', value: 'slider' },
    ].filter(({ value }) => {
        if (value === 'slider') {
            return true;
        }
        if (!depositCalculationsStore.depositType) {
            return true;
        }
        return depositCalculationsStore.depositType.conditions.some(({ duration, intervals }) => {
            return (Number(value) >= duration.min && Number(value) <= duration.max) && intervals.length > 0 && Number(value) >= (depositCalculationsStore.minDuration || 0) && Number(value) <= (depositCalculationsStore.maxDuration || Infinity   )
        })
    }), [depositCalculationsStore.depositType, depositCalculationsStore.minDuration, depositCalculationsStore.maxDuration])

    useEffect(() => {
        if (sliderDuration) {
            return
        }

        if (!depositCalculationsStore.duration) {
            return
        }
        console.log('some')
        if (durationOptions.some(({ value }) => Number(value) === depositCalculationsStore.duration)) {
            return
        }

        depositCalculationsStore.setDuration(Number(durationOptions[0].value))
    }, [depositCalculationsStore.duration, sliderDuration, durationOptions])
    
    return (
        <BeveledContainer style={{ display: 'flex', gap: '24px' }}>
            <Text color="secondary">Срок накопления</Text>
                <Root>
                    {durationOptions.map(o => {
                        const active = (sliderDuration ? 'slider' : depositCalculationsStore.duration?.toString() || '1') === o.value;

                        return (
                            <OptionButton
                                disabled={disabled}
                                key={o.value}
                                onClick={() => {
                                    if (o.value === 'slider') {
                                        setSliderDuration(true)
                                        return
                                    }
                                    setSliderDuration(false)
                                    depositCalculationsStore.setDuration(Number(o.value))
                                }}
                                color="primary"
                                variant={active ? 'contained' : 'outlined'}
                                style={{ flex: o.value === 'slider' ? 'unset' : undefined}}
                            >
                                {o.label}
                            </OptionButton>
                        );
                    })}
                </Root>
            {sliderDuration && <Slider
                id="duration"
                value={depositCalculationsStore.duration || 0}
                setValue={value => depositCalculationsStore.setDuration(value)}
                min={depositCalculationsStore.minDuration}
                max={depositCalculationsStore.maxDuration}
                step={DELTA_DURATION}
                label="Срок инвестирования, месяцев"
                disabled={disabled || isUndefined(depositCalculationsStore.minDuration)}
            />}
        </BeveledContainer>
    )
});
