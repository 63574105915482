import { action, computed, makeObservable, observable } from 'mobx';
import { DepositType } from '../dto';
import { isUndefined } from 'lodash';
import { ConditionForNotice, type DepositReturn, depositReturns, DepositSettings, getDepositsSettings, getInterval } from '../utils/deposits';
import { DEFAULT_REPLENISHMENT, PER_MONTH_DEPOSIT_TYPE_ID, END_TERM_DEPOSIT_TYPE_ID, END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID } from './constants';
import BigNumber from 'bignumber.js';
class DepositCalculationsStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    depositTypes: DepositType[] = [];

    @observable
    depositType: DepositType | null = null;

    @observable
    settings: Map<string, DepositSettings> | null = null;

    @observable
    minSum: number | undefined;

    @observable
    maxSum: number | undefined;

    @observable
    sum: number | undefined;

    @observable
    noticeConditions: ConditionForNotice[] = [];

    @observable
    minDuration: number | undefined;

    @observable
    maxDuration: number | undefined;

    @observable
    duration: number | undefined;

    @observable
    replenishment: number = DEFAULT_REPLENISHMENT;

    @observable
    alias: string = '';

    @observable
    averageProfitability: string = '';

    @observable
    depositReturn: DepositReturn | null = null;

    @action
    init(depositTypes: DepositType[], averageProfitability: string) {
        this.depositTypes = depositTypes;
        this.averageProfitability = averageProfitability;
        this.settings = getDepositsSettings(depositTypes);
        this.alias = '';
        this.depositReturn = null;
        this.depositType = null;
    }

    @action
    setDepositType(depositType: DepositType) {
        if (!this.settings) return;

        const current = this.settings.get(depositType.id);
        if (!current) return;

        this.depositType = depositType;

        this.minSum = current.minSum;
        this.maxSum = current.maxSum;
        this.noticeConditions = current.noticeConditions;

        this.setSum(this.minSum);
    }

    @action
    setSum(sum: number | undefined) {
        this.sum = sum;

        if (isUndefined(sum) || !this.depositType) {
            this.minDuration = undefined;
            this.maxDuration = undefined;
            return;
        }

        const conditions = this.depositType.conditions.filter(c =>
            c.intervals.some(i => i.minSum <= sum && (!i.maxSum || sum <= i.maxSum))
        );

        if (conditions.length === 0) {
            this.minDuration = undefined;
            this.maxDuration = undefined;
            return;
        }

        const sortedConditions = conditions.sort((a, b) => a.duration.min - b.duration.min);

        this.minDuration = sortedConditions[0].duration.min;
        this.maxDuration = sortedConditions[sortedConditions.length - 1].duration.max;

        if (!this.duration || this.minDuration > this.duration || this.duration > this.maxDuration) {
            this.setDuration(this.minDuration);
        }
    }

    @action
    setDuration(duration: number | undefined) {
        this.duration = duration;
    }

    @action
    setReplenishment(replenishment: number) {
        this.replenishment = replenishment;
    }

    @action
    setAlias(alias: string) {
        this.alias = alias;
    }

    @action
    setDepositReturn(depositReturn: DepositReturn) {
        this.depositReturn = depositReturn
        if (depositReturn.id !== '2') {
            const endTermDepositType = this.depositTypes.find(deposit => deposit.id === END_TERM_DEPOSIT_TYPE_ID)
            if (!endTermDepositType) {
                return
            }
            this.setDepositType(endTermDepositType)
            return
        }
        const newDepositType = this.depositTypes.find(deposit => deposit.id === END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID)
        if (!newDepositType) {
            return
        }
        this.setDepositType(newDepositType)
    }

    @computed
    get interval() {
        if (!this.depositType || isUndefined(this.sum) || isUndefined(this.duration)) {
            return undefined;
        } else {
            return getInterval(this.depositType.conditions, this.sum!, this.duration!);
        }
    }

    @computed
    get perMonthDepositInfo() {
        if (this.depositType?.id !== PER_MONTH_DEPOSIT_TYPE_ID) {
            return undefined;
        }

        if (
            this.interval?.sharePercent === undefined ||
            this.sum === undefined ||
            this.duration === undefined ||
            !this.averageProfitability
        ) {
            return undefined;
        }

        let wholeIncome = BigNumber(0);

        const percent = BigNumber(this.interval.sharePercent)
            .multipliedBy(this.averageProfitability)
            .dividedBy(100)
            .dividedBy(12);
        for (let month = 0; month < this.duration; month++) {
            wholeIncome = BigNumber(this.replenishment)
                .multipliedBy(month)
                .plus(this.sum)
                .multipliedBy(percent)
                .plus(wholeIncome);
        }

        const perMonthIncome = wholeIncome.dividedBy(this.duration);
        return {
            perMonthIncome: perMonthIncome.toNumber(),
            wholeIncome: wholeIncome.toNumber(),
        };
    }

    @computed
    get endTermDepositInfo() {
        if (this.depositType?.id !== END_TERM_DEPOSIT_TYPE_ID && this.depositType?.id !== END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID) {
            return undefined;
        }

        if (
            this.interval?.sharePercent === undefined ||
            this.sum === undefined ||
            this.duration === undefined ||
            !this.averageProfitability
        ) {
            return undefined;
        }

        const incomeWithoutPercents = this.sum + this.replenishment * (this.duration - 1);
        let income = BigNumber(this.sum);

        const percent = BigNumber(this.interval.sharePercent)
            .multipliedBy(this.averageProfitability)
            .dividedBy(100)
            .dividedBy(12);
        for (let month = 0; month < this.duration; month++) {
            const afterReplenishment = income.plus(month === 0 ? 0 : this.replenishment);
            income = afterReplenishment.multipliedBy(percent).plus(afterReplenishment);
        }

        const diff = income.minus(incomeWithoutPercents);
        return {
            incomeWithoutPercents,
            income: income.toNumber(),
            diff: diff.toNumber(),
        };
    }

    @computed
    get needSumNotice() {
        const sum = this.sum;
        const duration = this.duration;

        return this.noticeConditions.some(
            c => sum === c.sum && duration && duration >= c.durationMin && duration <= c.durationMax
        );
    }

    @computed
    get error() {
        let errors: Record<'depositType' | 'depositReturn', string | undefined> = {
            depositType: undefined,
            depositReturn: undefined
        }
        if (!depositCalculationsStore.duration || !depositCalculationsStore.sum) {
            errors.depositType = 'Некорректные настройки продукта. Выберите другой вариант';
        }
        if (!depositCalculationsStore.depositType) {
            errors.depositType = 'Выберите продукт';
        }
        if ((depositCalculationsStore.depositType?.id === END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID || depositCalculationsStore.depositType?.id === END_TERM_DEPOSIT_TYPE_ID) && !depositCalculationsStore.depositReturn) {
            errors.depositReturn = 'Выберите возврат'
        }
        if (errors.depositType || errors.depositReturn) {
            return errors
        }
        return undefined;
    }
}

export const depositCalculationsStore = new DepositCalculationsStore();
