export const DELTA_SUM = 1000;
export const DELTA_DURATION = 1;

export const DEFAULT_REPLENISHMENT = 100000;
export const MAX_REPLENISHMENT = 5 * DEFAULT_REPLENISHMENT;
export const MIN_REPLENISHMENT = 0;
export const STEP_REPLENISHMENT = 1000;

export const PER_MONTH_DEPOSIT_TYPE_ID = '6';
export const END_TERM_DEPOSIT_TYPE_ID = '7';

export const END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID = '8';

export const PER_MONTH_DEPOSIT_TYPE_NAME = 'Инвесткопилка (доход)'
export const END_TERM_DEPOSIT_TYPE_NAME = 'Инвесткопилка (накопление)'

export const SHOW_CLOSED_DEPOSITS_STORAGE_KEY = 'show-closed-deposits'