import { FormikConfig, useFormikContext } from 'formik';
import * as yup from 'yup';
import { isValidEmail, isValidPhone } from '../../utils/validators';
import { registerRequest } from './registerRequest';
import { getRegistrationData, RegistrationData } from '../../dto';
import { messageStore } from '../../store/messageStore';

export type IRegisterValues = {
    name: string;
    surname: string;
    patronymic: string;
    email: string;
    phone: string;
    agreementChecked: boolean;
    smartToken: string;
    failedRequestCount: number;
    agentId: string;
    hasAgentId: boolean;
    queryAgentId: string;
};

export const useRegisterFormik = () => useFormikContext<IRegisterValues>();

export const getRegisterFormikConfig = (onComplete: () => void): FormikConfig<IRegisterValues> => {
    return {
        initialValues: {
            name: '',
            surname: '',
            patronymic: '',
            email: '',
            phone: '',
            agreementChecked: false,
            smartToken: '',
            failedRequestCount: 0,
            agentId: '',
            hasAgentId: false,
            queryAgentId: ''
        },
        onSubmit: (values, { setSubmitting, setFieldValue, setFieldTouched }) => {
            const { name, surname, patronymic, phone, email, smartToken, failedRequestCount, agentId, hasAgentId, queryAgentId } = values;
            const registrationData: RegistrationData = getRegistrationData({
                name,
                surname,
                patronymic,
                phone,
                email,
                smartToken,
                agentId: hasAgentId ? agentId : (queryAgentId || null),
            });
            registerRequest
                .request(registrationData)
                .then(() => {
                    messageStore.success(`Регистрация завершена. Пароль отправлен на ${email}`);
                })
                .then(onComplete)
                .catch(_ => {
                    setFieldValue('failedRequestCount', failedRequestCount + 1);
                    setFieldValue('smartToken', '');
                    setFieldTouched('smartToken', false);
                })
                .finally(() => setSubmitting(false));
        },
        validationSchema: yup.object().shape({
            name: yup.string().test('required', 'Укажите имя', function (this, name) {
                return this.parent.step === 0 || !!name;
            }),
            surname: yup.string().test('required', 'Укажите фамилию', function (this, surname) {
                return this.parent.step === 0 || !!surname;
            }),
            phone: yup
                .string()
                .test('required and valid', 'Укажите номер телефона в корректном формате', function (this, phone) {
                    return this.parent.step === 0 || isValidPhone(phone);
                }),
            email: yup
                .string()
                .test('required and valid', 'Укажите электронную почту в корректном формате', function (this, email) {
                    return this.parent.step === 0 || isValidEmail(email);
                }),
            agreementChecked: yup
                .boolean()
                .test(
                    'required and valid',
                    'Чтобы продолжить, вы должны согласиться с условиями',
                    function (this, checked) {
                        return this.parent.step === 0 || checked;
                    }
                ),
            smartToken: yup.string().required('Введите капчу'),
            agentId: yup.string().when("hasAgentId", {
                is: true,
                then: (schema) => schema.required('Введите идентификатор агента')
            })
        }),
    };
};
