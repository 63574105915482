import { JsonObject, JsonProperty } from "typescript-json-serializer";
import { JsonDate } from "./util";

@JsonObject()
export class GetInnInput {
    @JsonProperty({ name: 'first_name' })
    name?: string;

    @JsonProperty({ name: 'last_name' })
    surname?: string;

    @JsonProperty({ name: 'middle_name' })
    patronymic?: string | null;

    @JsonDate({ name: 'birth_date' })
    birthDate?: Date;

    @JsonProperty({ name: 'passport_seria' })
    passportSeria?: string;

    @JsonProperty({ name: 'passport_number' })
    passportNumber?: string;
}

export class Inn {
    @JsonProperty()
    inn?: string;
}