import Button from "@mui/material/Button"
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import { getInnRequest } from "./getINNRequest"
import { IProfileValues } from "./profileFormik";

type Props = {
    formik: FormikProps<IProfileValues>;
    disabled?: boolean
}

export const GetInnButton = observer(({ formik, disabled }: Props) => {
    const handleClick = () => {
        getInnRequest.request({
            birthDate: formik.values.birthDate || undefined,
            name: formik.values.name,
            passportNumber: formik.values.passportNumber,
            passportSeria: formik.values.passportSeria,
            patronymic: formik.values.patronymic,
            surname: formik.values.surname,
        }).then((data) => {
            if (!data.inn) {
                return
            }
            formik.setFieldValue('TIN', data.inn)
        }).catch(() => {})
    }
    const isDisabled = !(formik.values.surname && formik.values.name && formik.values.birthDate && formik.values.passportSeria && formik.values.passportNumber && formik.values.issuedAt)
    return <Button style={{ height: '100%' }} disabled={disabled || isDisabled || getInnRequest.isLoading} onClick={handleClick} >Получить ИНН</Button>
})