import { JsonSerializer, throwError, JsonProperty } from 'typescript-json-serializer';
import { JsonPropertyOptions } from 'typescript-json-serializer/dist/json-property';
import { parse, format } from 'date-fns';

export const serializer = new JsonSerializer({
    errorCallback: throwError,
});

export const DEFAULT_DATE = new Date(0);

const validateEnum = (enumType: object, value: unknown) => {
    if (typeof value !== 'string' || !Object.values(enumType).includes(value))
        throw new TypeError('Wrong enum value: ' + String(value));

    return value;
};

export const JsonEnum = ({ type, ...rest }: Omit<JsonPropertyOptions, 'type'> & { type: object }) =>
    JsonProperty({
        ...rest,
        beforeDeserialize: (v: string | null) => v?.toUpperCase().replace(/\s/g, '_'),
        afterDeserialize: val => validateEnum(type, val),
        afterSerialize: (v: string | null) => v?.toLowerCase(),
    });

const SERVER_DATE_WITH_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
const SERVER_DATE_FORMAT = "dd.MM.yyyy";

export const deserializeDate = (s: string | null | undefined) => {
    if (!s) {
        return s
    }
    if (s.length === SERVER_DATE_FORMAT.length) {
        const [y, m, d] = s.split('.').reverse().map(s => Number(s))
        return new Date(y, m - 1, d).toISOString()
    } 
    return s ? new Date(s).toISOString() : s;
}
export const serializeDate = (d: Date | null | undefined) => (d ? format(d, SERVER_DATE_FORMAT) : d);

export const JsonDate = (options?: JsonPropertyOptions) =>
    JsonProperty({ ...options, beforeDeserialize: deserializeDate, beforeSerialize: serializeDate });
