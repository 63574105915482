import { mockApi } from '../../../api/constants';
import { FakeRequest } from '../../../api/FakeRequest';
import { HttpRequest } from '../../../api/HttpRequest';
import { serializer } from '../../../dto';
import { GetInnInput, Inn } from '../../../dto/GetInnDto';

export const getInnRequest = !mockApi
    ? new HttpRequest<GetInnInput, Inn>({
          method: 'post',
          path: () => '/users/inn/',
          body: data => serializer.serializeObject(Object.assign(new GetInnInput(), { ...data })) || {},
          mapper: data => serializer.deserializeObject(data, Inn) as Inn,
      })
    : new FakeRequest<GetInnInput, Inn>({
          fakeData: () =>
              serializer.deserializeObject(
                  {
                    inn: '315399237553'
                  },
                  Inn
              ) as Inn,
      });
