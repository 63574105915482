import React, { FC, useCallback, useMemo } from 'react';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { DepositType } from '../../../dto';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { observer } from 'mobx-react';
import { Select } from '../../../components/Select';
import { END_TERM_DEPOSIT_TYPE_ID, PER_MONTH_DEPOSIT_TYPE_ID, END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID } from '../../../store/constants';

type Props = {
    disabled?: boolean;
};

export const SelectDepositType: FC<Props> = observer(({ disabled = false }) => {
    const onChange = useCallback((value: DepositType) => {
        depositCalculationsStore.setDepositType(value);
    }, []);

    const renderValue: (v: DepositType, inList: boolean) => React.ReactNode = useCallback(item => {
        if (item.id === END_TERM_DEPOSIT_TYPE_ID) {
            return (
                <>
                    В конце срока
                </>
            );
        }

        if (item.id === PER_MONTH_DEPOSIT_TYPE_ID) {
            return (
                <>
                    Ежемесячно
                </>
            );
        }
        return item.name;
    }, []);

    const items = useMemo(() => {
        return depositCalculationsStore.depositTypes.filter(type => type.id === END_TERM_DEPOSIT_TYPE_ID || type.id === PER_MONTH_DEPOSIT_TYPE_ID)
    }, [depositCalculationsStore.depositTypes])

    const value = useMemo(() => {
        if (depositCalculationsStore.depositType?.id === END_TERM_DEPOSIT_RETURN_ANYTIME_TYPE_ID) {
            if (depositCalculationsStore.depositReturn?.id === '2') {
                const endTermDepositType = depositCalculationsStore.depositTypes.find(deposit => deposit.id === END_TERM_DEPOSIT_TYPE_ID)
                if (endTermDepositType) {
                    return endTermDepositType
                }
            }
        }
        return depositCalculationsStore.depositType
    }, [depositCalculationsStore.depositType, depositCalculationsStore.depositReturn])

    return (
        <BeveledContainer>
            <Select<DepositType>
                label="Выплата дохода"
                name={'depositType'}
                items={items}
                renderValue={renderValue}
                keyGetter={item => item.id}
                error={!!depositCalculationsStore.error}
                helperText={depositCalculationsStore.error?.depositType}
                variant="standard"
                value={value}
                onChange={onChange}
                readOnly={disabled}
                required
            />
        </BeveledContainer>
    );
});
