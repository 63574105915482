import React, { FC, useCallback, useMemo } from 'react';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { DepositType } from '../../../dto';
import { depositCalculationsStore } from '../../../store/depositCalculationsStore';
import { observer } from 'mobx-react';
import { Select } from '../../../components/Select';
import { END_TERM_DEPOSIT_TYPE_ID, PER_MONTH_DEPOSIT_TYPE_ID } from '../../../store/constants';
import { DepositReturn, depositReturns } from '../../../utils/deposits';

type Props = {
    disabled?: boolean;
};


export const SelectDepositReturn: FC<Props> = observer(({ disabled = false }) => {
    const onChange = useCallback((value: DepositReturn) => {
        depositCalculationsStore.setDepositReturn(value);
    }, []);

    const renderValue: (v: DepositReturn, inList: boolean) => React.ReactNode = useCallback(item => {
        return item.name;
    }, []);


    return (
        <BeveledContainer>
            <Select<DepositReturn>
                label="Возврат"
                name={'depositReturn'}
                items={depositReturns}
                renderValue={renderValue}
                keyGetter={item => item.id}
                error={!!depositCalculationsStore.error}
                helperText={depositCalculationsStore.error?.depositReturn}
                variant="standard"
                value={depositCalculationsStore.depositReturn}
                onChange={onChange}
                readOnly={disabled}
                required
            />
        </BeveledContainer>
    );
});
